import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Consultancy = () => {
  const data = useStaticQuery(graphql`
    query {
      consultancy: file(relativePath: { eq: "Consultancy.png" }) {
        childImageSharp {
          fluid(maxWidth: 915, maxHeight:608) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="mx-auto mb-4 mb-xl-0">
      <Img fluid={data.consultancy.childImageSharp.fluid} />
    </div>
  )
}

export default Consultancy
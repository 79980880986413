import React, { Component } from "react"
import Container from "react-bootstrap/Container"
import Card from "react-bootstrap/Card"
import ListGroup from 'react-bootstrap/ListGroup'
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Consultancy from "./Consultancy"

export class ConsultancyTab extends Component {
  render() {
    return (
      <Container className="my-5">
        <Row className="align-items-center">
          <Col xl={{span: 5, offset: 0, order: 'last'}} xs={{span: 6, offset: 3}} className="pl-lg-5">
            <Consultancy/>
          </Col>
          <Col xl={7}>
            <p className="lead  text-primary">
              <em>
                “Did you know that Waite Psychology works with schools at a group and whole school level through their
                consultancy and project work? This is because applications of psychology go beyond supporting individual
                children or young people, and can result in a greater impact when applied to meet whole school targets
                and systemic change.”
              </em>
            </p>
            <p>
              At Waite Psychology our consultancy and project work are offered as a bespoke service created through
              collaboration, that develops a tailored package of solutions to a school’s group or organisational targets.
              This is inclusive of our engagement in research and development projects, consultancy-based advice for
              whole school development plans, policy development and dissemination of whole school or group
              interventions.
            </p>
          </Col>
        </Row>
        <Card border="primary" className="my-5">
          <Card.Header className="bg-primary text-white"><h4>Ideas and examples include:</h4></Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>Developing and disseminating an emotional regulation and relationship
              policy underpinned by an 'Attachment Aware and Trauma Responsive' approach.
            </ListGroup.Item>
            <ListGroup.Item>Consulting on the use of open assessments delivered by a Special Educational Needs and Disability Co-ordinator (SENDCo) to evidence intervention progress.
            </ListGroup.Item>
            <ListGroup.Item>Setting up solution circles with clusters of staff to enhance their
              peer support problem-solving skills.
            </ListGroup.Item>
            <ListGroup.Item>Disseminating and evaluating a school-wide executive functioning
              skills project.
            </ListGroup.Item>
            <ListGroup.Item>Using socio-metrics to unpick the social dynamics of a class to inform
              a friendship-based intervention.
            </ListGroup.Item>
            <ListGroup.Item>Setting up and evaluating the impact of playground buddies.
            </ListGroup.Item>
            <ListGroup.Item>Evaluating the use of whole class mindfulness activities on attention
              and concentration skills of children in their afternoon lessons.
            </ListGroup.Item>
            <ListGroup.Item>Screening the emotional, psychological and social wellbeing of
              secondary-aged pupils to inform whole school intervention.
            </ListGroup.Item>
          </ListGroup>
        </Card>
        <p>
          If these ideas have evoked some of your own, then please contact us! We would love to discuss your ideas
          further and plan more exciting projects to support all children and young people in your school!
        </p>
      </Container>
    )
  }
}
import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"

const Solutions = () => {
  const data = useStaticQuery(graphql`
    query {
      solutions: file(relativePath: { eq: "Solutions.png" }) {
        childImageSharp {
          fluid(maxWidth: 1610, maxHeight: 1765) {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  return (
    <div className="mx-auto mb-4 mb-lg-0">
      <Img fluid={data.solutions.childImageSharp.fluid} />
    </div>
  )
}

export default Solutions
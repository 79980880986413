import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import ServicesTabs from "../components/ServicesTabs"
import { MemoryRouter } from "react-router-dom"
import Container from 'react-bootstrap/Container'

const ServicesPage = () => (
  <Layout>
    <SEO title="Services for Schools" />
    <Container className="my-4">
      <MemoryRouter>
      <h1 className="text-primary">Services for Schools</h1>
      <ServicesTabs/>
      </MemoryRouter>
    </Container>
  </Layout>
)

export default ServicesPage

import React, { Component } from "react"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Rainbow from "./Rainbow"
import Card from "react-bootstrap/Card"
import ListGroup from "react-bootstrap/ListGroup"

export class CovidTab extends Component {
  render() {
    return (
      <Container className="my-5">
        <Row>
          <Col xl={{span: 5, offset: 0}} lg={{span: 6, offset: 3}} xs={{span: 8, offset: 2}} className="pr-lg-5">
            <Rainbow/>
          </Col>
          <Col xl={7}>
            <p>
              The 2020 COVID-19 lock-down impacted all educational psychology practices across the country. Guidance
              from the British Psychological Society (Division of Educational and Child Psychology) advised against any
              face-to-face work. Psychologists were no longer able to visit children or young people still in school, at
              home, or to complete direct assessments. Overnight, our only way of working was no longer viable. This
              risked the ongoing support network provided by educational psychology services, for schools and families,
              being withdrawn and, crucially, at a point when their skills and advice would be needed most!
              Particularly, skills within critical incident support responses.
            </p>
            <p>
              Very luckily, with access to remote working via indirect assessments, telephone and video consultations
              and observations, together with social networking, our own little rainbow was formed, creating new and
              continued ways of supporting schools to find solutions.
            </p>
          </Col>
        </Row>
        <Card border="primary" className="my-5">
          <Card.Header className="bg-primary text-white"><h4>Examples of our remote support has included:</h4></Card.Header>
          <ListGroup variant="flush">
            <ListGroup.Item>Ongoing completion of consultation-based action plans delivered at home or in school, informed by indirect assessments.</ListGroup.Item>
            <ListGroup.Item>Ongoing completion of comprehensive reports informed by consultation approaches, indirect assessments and observations via video conferencing.</ListGroup.Item>
            <ListGroup.Item>Ongoing completion of action plans to aid secondary school transitions and re-transition into school.</ListGroup.Item>
            <ListGroup.Item>Early intervention support and consultation-based action plans for new school starters.</ListGroup.Item>
            <ListGroup.Item>Therapeutic and emotional support for parents and staff via frequent touch-base telephone calls.</ListGroup.Item>
            <ListGroup.Item>Therapeutic group work for staff to aid emotional awareness and wellbeing through explorations of Van De Kolk's pre-conditions of trauma.</ListGroup.Item>
            <ListGroup.Item>Training via video conferencing for school staff.</ListGroup.Item>
            <ListGroup.Item>Advice for parents home-schooling their children and young people with Education Health and Care Plans (EHCP).</ListGroup.Item>
            <ListGroup.Item>Contributions to virtual multi-agency meetings and next-step planning.</ListGroup.Item>
            <ListGroup.Item>Consultancy based advice on returning to school and the 'Recovery Curriculum.'</ListGroup.Item>
            <ListGroup.Item>Sharing updates, resources and guidance documents on Waite Psychology's <a target="_blank" rel="noreferrer" href="https://www.facebook.com/waitepsychology/">Facebook page</a> and <a target="_blank" rel="noreferrer" href="https://twitter.com/waitepsychology">Twitter account</a>.</ListGroup.Item>
          </ListGroup>
        </Card>
        <h3 className="text-secondary">Predicting Ongoing 'New Ways' of Working</h3>
        <p>
          As it is suspected to be an ever evolving 'rocky road' with localised responses to COVID-19 and fears of a
          second outbreak, Waite Psychology will continue to utilise remote working approaches, in aspects or in
          entirety, if required.
        </p>
        <p>
          We will continue to arrange all support collaboratively with our individual schools, and continue to take
          on new school enquires. We will work within our COVID-19 policy 'Returning to School', that details
          guidance informed by our own professional bodies on how we can complete school visits safely. We will
          follow our individual school's policies and procedures and respond to all emerging government guidance,
          advice or restrictions. Wherever possible, we will minimise the disruption to our schools by continuing
          effective remote working approaches alongside school visits, so that planned work can be completed if a
          school visit cannot be made.
        </p>
        <p>
          In all cases, we will continue to “weather the storm” and support in the best way we can!
        </p>
      </Container>
    )
  }
}
import React, { Component } from "react"
import Tabs from "react-bootstrap/Tabs"
import Tab from "react-bootstrap/Tab"
import { IndividualsTab } from "./servicesTabs/IndividualsTab"
import { TrainingTab } from "./servicesTabs/TrainingTab"
import { ConsultancyTab } from "./servicesTabs/ConsultancyTab"
import { CovidTab } from "./servicesTabs/CovidTab"

class ServicesTabs extends Component {
  state = {
    items: {
      default: "1",
    }
  };

  toggleTabs = (type, tab) => e => {
    e.preventDefault();
    if (this.state.items[type] !== tab) {
      let items = { ...this.state.items };
      items[type] = tab;
      this.setState({
        items
      });
    }
  };

  render() {
    return (
      <div className="mt-4">
        <Tabs defaultActiveKey="individuals" id="uncontrolled-tab-example" className="nav-fill">
          <Tab eventKey="individuals" title="Individual Children and Young People">
            <IndividualsTab/>
          </Tab>
          <Tab eventKey="training" title="Staff Training">
            <TrainingTab/>
          </Tab>
          <Tab eventKey="consultancy" title="Consultancy and Project Work">
            <ConsultancyTab />
          </Tab>
          <Tab eventKey="covid" title="COVID-19 Remote Support">
            <CovidTab />
          </Tab>
        </Tabs>
      </div>
    );
  }
}

export default ServicesTabs;
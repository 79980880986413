import React, { Component } from "react"
import Solutions from "./Solutions"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"

export class IndividualsTab extends Component {
  render = () => (
    <Container className="my-5">
      <Row className="align-content-center">
        <Col xl={{span: 3, offset: 0, order: 'last'}} lg={{span: 4, offset: 0, order: 'last'}} md={{span: 4, offset: 4}} xs={{span: 6, offset: 3}} className="pl-lg-5">
          <Solutions/>
        </Col>
        <Col xl={9} lg={8}>
          <p>
            A primary role for educational psychologists is to support individual children and young people
            displaying
            the greatest level of need. Support focuses across the four areas of the Special Educational Needs and
            Disability (SEND) Code of Practice. The aim is to understand the depth of a child’s strengths and needs,
            to
            provide solutions that remove barriers to their learning. All approaches and solutions are underpinned
            by
            expertise in child and adolescent development, and applied psychology.
          </p>
          <p>
            To identify the right solutions to help, our involvement makes effective use of assessments, pupil
            voice,
            consultation and action planning, and report writing, whilst also aiding parental involvement.
          </p>
        </Col>
      </Row>
      <Card border="primary" className="my-5">
        <Card.Header className="bg-primary text-white"><h4>Assessments</h4></Card.Header>
        <Card.Body className="p-4">
          <Card.Text>
            Assessments help to triangulate information and clarify or identify areas of strength and need. They play a
            key
            role in focusing intervention, informing action plans and reviewing progress over time.
          </Card.Text>
          <Card.Text>
            We use a variety of assessments within each area of SEND (physical and sensory; communication and
            interaction;
            cognition and learning; and social, emotional and mental health). They include direct and indirect
            assessments,
            that are either formal or informal. Frequently used are standardised assessments, criterion referenced
            assessments, dynamic assessment, play-based assessments, observations and questionnaires.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card border="secondary" className="mb-5">
        <Card.Header className="bg-secondary text-white"><h4>Pupil Voice</h4></Card.Header>
        <Card.Body className="p-4">
          <Card.Text>
            To support children and young people, it is essential to gain their perspectives, and have their voices
            heard
            and
            acted upon within the process of an educational psychologist’s involvement. Only through gaining a pupil’s
            voice
            can we truly understand their lived experiences. Only through acting upon their opinions, can children and
            young
            people feel included in finding solutions to best help and support them.
          </Card.Text>
          <Card.Text>
            We use a variety of approaches to gain a child or young person’s opinions and to aid their participation. We
            apply techniques depending upon a child’s age and development, often utilising play-based creative
            techniques,
            therapeutic approaches, personal-construct approaches, self-report questionnaires or less formal
            conversations.
            Wherever possible, we consider person-centred planning approaches that enable children or young people to be
            part of solution-focused, problem-solving consultations.
          </Card.Text>
        </Card.Body>
      </Card>
      <Card border="primary" className="mb-5">
        <Card.Header className="bg-primary text-white"><h4>Consultation and Action Planning</h4></Card.Header>
        <Card.Body className="p-4">
          <Card.Text>
            Consultation is a joint problem-solving approach with school staff, which draws upon experiences and
            expertise,
            whilst pulling together all triangulated information and assessment results, to gain a deeper understanding
            of
            a
            child or young person’s needs. This approach results in the formulation of an action plan, detailing
            practical
            creative solutions and interventions tailored to each individual child or young person.
          </Card.Text>
          <Card.Text>
            Our consultation approach and action plans work within the ‘assess, plan, do, review’ process of the
            graduated
            response. Action plans are frequently reviewed to uncover a deeper understanding of a child or young
            person’s
            strengths and needs (their responses to intervention), and the next steps needed to effect further change.
          </Card.Text>
          <Card.Text>
            Our consultation approach often includes parents and supports joined-up working, shared understandings of a
            child or young person’s needs and develops solutions between home and school.
          </Card.Text>
        </Card.Body>
      </Card>

      <Card border="secondary" className="mb-5">
        <Card.Header className="bg-secondary text-white"><h4>Reports</h4></Card.Header>
        <Card.Body className="p-4">
          <Card.Text>
            Reports are provided for different purposes, and reflect the aims and outcome of our involvement.
          </Card.Text>
          <Card.Text>
            Reports can vary from brief summaries detailing next-step recommendations, to consultation action plans
            detailing
            assessment results and agreed solutions, to more comprehensive reports detailing strengths and SEND needs,
            assessment results and provisions required to meet short, medium and long term outcomes.
          </Card.Text>
          <Card.Text>
            Ideally, our model of practice is within the division of consultation action plan reports, which are updated as
            part of the ‘assess, plan, do, review’ process. However, we understand that at times a comprehensive report is
            requested for specific purposes. For example, to thoroughly understand a child or young person’s presenting
            needs; to seek multi-agency support; or as part of a request for an education, health and care needs
            assessment.
            Our consultation action plans are often submitted as evidence of the graduated response, or comprehensive
            reports
            are requested following two rounds of ‘assess, plan, do, review’, and when additional provisions are being
            sought. Equally, we can provide comprehensive reports in addition to the initial action plan process. Overall,
            we apply a flexible approach to report writing that ensures we provide a service in a style that best meets
            the
            needs of the children and young people we support (and their schools too!).
          </Card.Text>
          <Card.Text>
            Our typical time frames for completing reports is within two or three weeks (once all information is gathered
            and solutions are agreed). Very often reports are written much sooner, sometimes within days! This is our
            preference, as we believe reports should be written when we have a current understanding of a child or young
            person (when all details are fresh in our minds), and to ensure the action plans can be implemented as soon as
            possible.
          </Card.Text>
        </Card.Body>
      </Card>
    </Container>
  )
}